import { Company } from '@/interfaces/vuex/company';
import { DepartmentsState } from '@/interfaces/vuex/department';
import { User } from '@/interfaces/vuex/user';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { AppNotification, MainState } from './state';

export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setCompany(state: MainState, payload: Company) {
    state.company = payload;
  },
  setDepartments(state: MainState, payload: DepartmentsState) {
    state.departments = payload;
  },
  setCurrentUserProfile(state: MainState, payload: User) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.splice(0);
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload
    );
  },
  setUserLocale(state: MainState, payload: string) {
    // Update the userProfile if it exists (for logged-in users)
    if (state.isLoggedIn && state.userProfile) {
      state.userProfile.locale = payload;
    } else {
      // update nonLoggedInUserLocale in the store to trigger the watcher in main.ts
      // This is critical for non-logged-in users where userProfile.locale might not be used
      state.nonLoggedInUserLocale = payload;
    }
  },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { commit } = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(
  mutations.setDashboardMiniDrawer
);
export const commitSetDashboardShowDrawer = commit(
  mutations.setDashboardShowDrawer
);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetToken = commit(mutations.setToken);
export const commitSetCompany = commit(mutations.setCompany);
export const commitSetDepartments = commit(mutations.setDepartments);
export const commitSetCurrentUser = commit(mutations.setCurrentUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetUserLocale = commit(mutations.setUserLocale);
