import { defineMessages } from '@/lib/i18n';
import sharedMessages from '@/lib/shared-messages';

const m = defineMessages({
  default: 'デフォルト',
  preAnalysis: '分析前',
  completed: '分析完了',
  impossible: '分析不可',
  reconfirmation: '要確認',
});

export class StatusId {
  private static readonly _values: StatusId[] = [];
  private static _valuesCopy: StatusId[] | null = null;

  static readonly DEFAULT = new StatusId(0, '', m.default);
  static readonly UPLOADING = new StatusId(10000, m.preAnalysis, m.preAnalysis);
  static readonly ASSORT = new StatusId(15000, m.preAnalysis, m.preAnalysis);
  static readonly ASSORT_DONE = new StatusId(
    20000,
    m.preAnalysis,
    m.preAnalysis
  );
  static readonly FIRST_CHECK_PROCESSING = new StatusId(
    30000,
    sharedMessages.analyzing,
    sharedMessages.analyzing
  );
  static readonly FIRST_CHECK_DONE = new StatusId(
    40000,
    sharedMessages.analyzing,
    sharedMessages.analyzing
  );
  static readonly SECOND_CHECK_PROCESSING = new StatusId(
    50000,
    sharedMessages.analyzing,
    sharedMessages.analyzing
  );
  static readonly SECOND_CHECK_DONE = new StatusId(
    60000,
    m.completed,
    m.completed
  );
  static readonly ERROR = new StatusId(90000, m.impossible, m.impossible);
  static readonly ERROR_RECONFIRMATION = new StatusId(
    91000,
    m.reconfirmation,
    m.reconfirmation
  );

  private constructor(
    readonly value: number,
    readonly messageId: string,
    readonly groupId: string
  ) {
    StatusId._values.push(this);
  }

  static fromStatusId(value: number): StatusId {
    const res = StatusId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return StatusId.DEFAULT;
    }
  }

  static values(): Array<number> {
    return StatusId._values.map((x) => x.value);
  }

  static uploadingToCompletedValues(): Array<number> {
    return [
      StatusId.UPLOADING.value,
      StatusId.ASSORT.value,
      StatusId.ASSORT_DONE.value,
      StatusId.FIRST_CHECK_PROCESSING.value,
      StatusId.FIRST_CHECK_DONE.value,
      StatusId.SECOND_CHECK_PROCESSING.value,
      StatusId.SECOND_CHECK_DONE.value,
      StatusId.ERROR.value,
      StatusId.ERROR_RECONFIRMATION.value,
    ];
  }

  static secondCheckDoneToCompletedValues(): Array<number> {
    return [StatusId.SECOND_CHECK_DONE.value];
  }

  static getDropdownOptions(): Array<StatusId> {
    if (this._valuesCopy === null) {
      this._valuesCopy = StatusId._values
        .filter((status) => status.value !== StatusId.DEFAULT.value)
        .map(
          (status) =>
            new StatusId(status.value, status.messageId, status.groupId)
        );
    }
    return this._valuesCopy;
  }

  static getClassFromStatusId = (statusId: number): string => {
    if (statusId <= 20000) return 'base-status-class status-pre-analysis-class';
    if (statusId <= 50000) return 'base-status-class status-analyzing-class';
    if (statusId == 60000) return 'base-status-class success';
    if (statusId == 90000) return 'base-status-class error';
    return 'base-status-class warning';
  };

  static getStatusFromStatusId = (statusId: number): string => {
    if (statusId <= 20000) return 'before';
    if (statusId <= 50000) return 'analyzing';
    if (statusId == 60000) return 'complete';
    if (statusId == 90000) return 'impossible';
    return 'check';
  };

  static updatableValues(): Array<number> {
    return [StatusId.SECOND_CHECK_DONE.value, StatusId.ERROR.value];
  }
}
