import { defineMessages } from '@/lib/i18n';
import sharedMessages from '@/lib/shared-messages';

const m = defineMessages({
  oldRevision: '旧版',

  needRevision: '要改訂',
  needRevisionDescription:
    '改訂日から5年以上が経過しています。サプライヤーに問い合わせて最新版のSDSを取得してください。',
  needRevisionTooltip:
    '最新版のSDSをアップロードするか、サプライヤーへの確認日を最新の日付に更新すると改訂ステータスが更新されます。',

  revised: '改訂済み',
  revisedDescription: '{revised_at} 改訂',

  supplierConfirmed: '確認済み',
  supplierConfirmedDescription:
    '{supplier_confirmed_at}にサプライヤーに確認済みです',
});

export class RevisionStatus {
  private static readonly _values: RevisionStatus[] = [];

  static readonly OLD = {
    fill: '#808080',
    color: '#000',
    text: m.oldRevision,
    showChip: true,
    description: sharedMessages.hasNewerSdsRevision,
  };
  static readonly NEED_REVISION = {
    fill: 'error',
    color: '#000',
    text: m.needRevision,
    showChip: true,
    descriptionClass: 'body-2 error--text',
    description: m.needRevisionDescription,
    tooltip: m.needRevisionTooltip,
  };
  static readonly REVISED = {
    fill: '#008000',
    color: '#ffffff',
    text: m.revised,
    description: m.revisedDescription,
  };
  static readonly SUPPLIER_CONFIRMED = {
    fill: '#58d68d',
    color: '#000',
    text: m.supplierConfirmed,
    description: m.supplierConfirmedDescription,
  };
}
