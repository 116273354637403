import { CompanyUserCreateRequestBody } from '@/interfaces/api/general/company';
import { Department } from '@/interfaces/model/general/department';
import { generalApi } from '@/lib/api/general';
import {
  dispatchCheckApiError,
  dispatchLogOut,
  MainContext,
} from '@/store/main/actionsMain';
import { commitAddNotification } from '@/store/main/mutations';
import { defineMessages } from '@/lib/i18n';
import { AxiosError } from 'axios';
import { ApiErrorResponse } from '@/interfaces/api/general/errorResponses';

const m = defineMessages({
  companyUserCreated: 'ユーザーを招待しました',
  companyUserUpdated: 'ユーザー情報を更新しました',
  companyUserDeleted: 'ユーザーを削除しました',
  csvUploadCompleted: 'CSVアップロードが完了しました。{details}',
  passwordReissueMailSent: 'パスワード再発行のメールを送信しました。',
});

export const actionGetCompanyUsers = async (
  context: MainContext,
  payload: {
    companyId: string;
  }
) => {
  try {
    return await generalApi.getCompanyUsers(
      context.state.token,
      payload.companyId
    );
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionCreateCompanyUser = async (
  context: MainContext,
  payload: {
    payload: CompanyUserCreateRequestBody;
    companyId: string;
  }
) => {
  try {
    const response = await generalApi.createCompanyUser(
      context.state.token,
      payload.companyId,
      payload.payload
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.companyUserCreated,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionUpdateCompanyUser = async (
  context: MainContext,
  payload: {
    companyId: string;
    userId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    position: string;
    phone: string;
    departments: Department[];
    roleId: number;
    is_subscribe: boolean;
  }
) => {
  try {
    const response = await generalApi.updateCompanyUser(
      context.state.token,
      payload.companyId,
      payload.userId,
      {
        first_name: payload.firstName,
        last_name: payload.lastName,
        middle_name: payload.middleName,
        position: payload.position,
        phone: payload.phone,
        department_id_list: payload.departments.map((d) => d.uuid),
        role_id: payload.roleId,
        is_subscribe: payload.is_subscribe,
      }
    );
    if (response.data) {
      commitAddNotification(context, {
        messageKey: m.companyUserUpdated,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionResetCompanyUserPassword = async (
  context: MainContext,
  payload: {
    companyId: string;
    userId: string;
  }
) => {
  try {
    const response = await generalApi.resetCompanyUserPassword(
      context.state.token,
      payload.companyId,
      payload.userId
    );
    if (response.data) {
      commitAddNotification(context, {
        messageKey: m.passwordReissueMailSent,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionDeleteCompanyUser = async (
  context: MainContext,
  payload: {
    companyId: string;
    email: string;
  }
) => {
  try {
    const response = await generalApi.deleteCompanyUser(
      context.state.token,
      payload.companyId,
      payload.email
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.companyUserDeleted,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionGetCompanyUserCsv = async (
  context: MainContext,
  payload: {
    companyId: string;
  }
) => {
  try {
    return await generalApi.getCompanyUserCsv(
      context.state.token,
      payload.companyId
    );
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionPostCompanyUserCsv = async (
  context: MainContext,
  payload: {
    companyId: string;
    file: File;
  }
) => {
  try {
    const formData = new FormData();
    formData.append('file', payload.file);
    const response = await generalApi.postCompanyUserCsv(
      context.state.token,
      payload.companyId,
      formData
    );
    if (response.data) {
      commitAddNotification(context, {
        messageKey: m.csvUploadCompleted,
        messageValues: { details: response.data },
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};
