import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { MainState } from './state';
import { PlanId } from '@/lib/enum';

export const getters = {
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  userProfile: (state: MainState) => state.userProfile,
  company: (state: MainState) => state.company,
  companyId: (state: MainState) => {
    const companyId = state.company.uuid;
    if (!companyId) {
      throw new Error('Company ID is not set');
    }
    return companyId;
  },
  isCompanySubscription: (state: MainState): boolean => {
    return state.company.plan_id > PlanId.FREE.value;
  },
  departments: (state: MainState) => state.departments,
  token: (state: MainState) => state.token,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
  userLocale: (state: MainState) =>
    state.isLoggedIn
      ? state.userProfile?.locale || state.nonLoggedInUserLocale
      : state.nonLoggedInUserLocale,
};

const { read } = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readToken = read(getters.token);
export const readCompany = read(getters.company);
export const readCompanyId = read(getters.companyId);
export const readDepartments = read(getters.departments);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readIsCompanySubscription = read(getters.isCompanySubscription);
export const readUserLocale = read(getters.userLocale);
