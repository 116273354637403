import {
  CompanyTagCreateRequestBody,
  CompanyTagUpdateRequestBody,
} from '@/interfaces/api/general/company';
import { generalApi } from '@/lib/api/general';
import {
  dispatchCheckApiError,
  dispatchLogOut,
  MainContext,
} from '@/store/main/actionsMain';
import { commitAddNotification } from '@/store/main/mutations';
import { AxiosError } from 'axios';
import { defineMessages } from '@/lib/i18n';
import { ApiErrorResponse } from '@/interfaces/api/general/errorResponses';

const m = defineMessages({
  tagAdded: 'タグが追加されました。',
  tagDeleted: 'タグが削除されました',
  tagUpdated: 'タグが更新されました',
});

export const actionGetCompanyTags = async (
  context: MainContext,
  payload: {
    companyId: string;
  }
) => {
  try {
    return await generalApi.getCompanyTag(
      context.state.token,
      payload.companyId
    );
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionDeleteCompanyTag = async (
  context: MainContext,
  payload: {
    id: number;
    companyId: string;
  }
) => {
  try {
    const response = await generalApi.deleteCompanyTag(
      context.state.token,
      payload.id,
      payload.companyId
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.tagDeleted,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionUpdateCompanyTag = async (
  context: MainContext,
  payload: {
    id: number;
    companyId: string;
    body: CompanyTagUpdateRequestBody;
  }
) => {
  try {
    const response = await generalApi.updateCompanyTag(
      context.state.token,
      payload.id,
      payload.companyId,
      payload.body
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.tagUpdated,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionCreateCompanyTag = async (
  context: MainContext,
  payload: {
    payload: CompanyTagCreateRequestBody;
    companyId: string;
  }
) => {
  try {
    const response = await generalApi.createCompanyTag(
      context.state.token,
      payload.companyId,
      payload.payload
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.tagAdded,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};
