import {
  CompanyUpdateRequestBody,
  CompanyUpdateRequestParams,
  CompanyWhitelistIpCreateRequestBody,
  CreateCompanySdsRequestUnit,
  DeleteCompanySdsesRequestBody,
  UpdateCompanySdsRequestBody,
  UpdateCompanySdsesRequestBody,
  UpdateReconfirmationSdsRequestBody,
  GetCompanySdsesRequestQuery,
  GetCompanySdsesAsExcelRequestQuery,
  GetCompanySdsesCountRequestQuery,
  DownloadCompanySdsesCSVRequestQuery,
  UploadCompanySdsesCSVRequestBody,
  CreateAttachmentRequestUnit,
} from '@/interfaces/api/general/company';
import { DynamicListResultRequestBody } from '@/interfaces/api/general/dynamicListResult';
import {
  InquiryRequestBody,
  QuotationRequestBody,
} from '@/interfaces/api/general/info';
import { UserUpdateRequestBody } from '@/interfaces/api/general/user';
import { ApiErrorResponse } from '@/interfaces/api/general/errorResponses';
import { generalApi } from '@/lib/api/general';
import { initialCompanyState } from '@/lib/constructor/vuex/company';
import { NewUser } from '@/lib/constructor/vuex/user';
import router from '@/router';
import {
  actionCreateCompanyTag,
  actionDeleteCompanyTag,
  actionGetCompanyTags,
  actionUpdateCompanyTag,
} from '@/store/main/actions/actionCompanyTag';
import {
  actionCreateCompanyUser,
  actionDeleteCompanyUser,
  actionGetCompanyUsers,
  actionGetCompanyUserCsv,
  actionPostCompanyUserCsv,
  actionResetCompanyUserPassword,
  actionUpdateCompanyUser,
} from '@/store/main/actions/actionCompanyUser';
import {
  actionCreateCompanyDepartment,
  actionDeleteCompanyDepartment,
  actionGetCompanyDepartments,
  actionUpdateCompanyDepartment,
} from '@/store/main/actions/actionDepartment';
import {
  actionCreateDynamicList,
  actionGetDynamicList,
  actionGetDynamicListResults,
  actionGetDynamicLists,
  actionGetExportDynamicListResults,
  actionRequestDynamicList,
  actionResumeDynamicLists,
  actionSuspendDynamicLists,
} from '@/store/main/actions/actionDynamicList';
import { actionSignUp } from '@/store/main/actions/actionSignUp';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import axios, { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetCompany,
  commitSetLoggedIn,
  commitSetToken,
  commitSetUserLocale,
  commitSetCurrentUser,
  commitSetDepartments,
} from './mutations';
import { AppNotification, MainState } from './state';
import { defineMessages } from '@/lib/i18n';
import sharedMessages from '@/lib/shared-messages';
import { Locale } from '@/interfaces/api/general/language';

const m = defineMessages({
  documentSent:
    '入力されたメールアドレスに資料を送信しました。メールボックスをご確認ください。',
  inquiryAcknowledgement:
    'お問い合わせいただきありがとうございます。2〜3営業日以内にご返信いたします。',
  estimateAcknowledgement: 'お見積頂きありがとうございます。',
  loginSuccess: 'ログインに成功しました。引き続きケミカンをご利用ください。',
  companyInfoUpdated: '企業情報の更新が完了しました',
  userInfoUpdated: 'ユーザー情報を更新しました。',
  tfaUpdated: '2段階認証の設定を更新しました。',
  logoutSuccess: 'ログアウトしました',
  logoutFailed: '正常にログアウトできませんでした。',
  tokenExpired: 'トークンの有効期限が切れました再度ログインしてください。',
  noPermission: '権限がありません。{details}',
  resourceNotFound: 'リソースが見つかりませんでした。{details}',
  invalidFileFormat: 'ファイル形式が不正です。{details}',
  requestTimeout: 'タイムアウトしました。{details}',
  payloadTooLarge: 'リクエストが大きすぎます。{details}',
  serverError: '[{status}] サーバーエラーです。{details}',
  unknownError: '不明なエラーです。{details}',
  checkingEmail: '入力されたメールアドレスをチェックしています。',
  passwordRecoveryEmailSent:
    '入力されたメールアドレスにパスワード再発行のメールを送信しました。',
  invalidEmail: 'メールアドレスが正しくありません。',
  passwordResetComplete: 'パスワードのリセット処理が完了しました',
  passwordResetFailed:
    'パスワードリセット処理に失敗しました。有効期限（15分）が切れているか、リンクが無効です。下記リンクより再度リセットをお試しください。\nhttps://sdsocr.com/#/password-recovery',
  processingError:
    '処理中にエラーが発生しました。運営へご連絡をお願いいたします。',
  ipAddressAdded: 'IPアドレスを追加しました',
  ipAddressDeleted: 'IPアドレスを削除しました',
  uploadFailed:
    'アップロードに失敗しました。アップロードの上限数が契約プラン上限数を超えていないかご確認ください。',
  fileDeleted: '選択したファイルを削除しました。',
  updateSuccess: '更新しました',
  redataRequested: '再データ化依頼を行いました',
  checklistUpdated: 'チェックリストを更新しました',
  checklistAdded: 'チェックリストに追加しました',
  processFailed:
    '処理に失敗しました。ページを更新して、再度実行をお願いいたします。',
  checklistRemoved: 'チェックリストから削除しました',
  notificationActivating: 'アカウント登録完了しています。',
  accountRegistrationComplete:
    'アカウント登録完了が完了しました。ログインをお願いいたします。',
  accountActivationMailSent: 'アカウント登録完了メールを送信しています。',
  accountActivationReceived:
    'アカウント登録完了メールの送信が完了しました。24時間以内にアカウントをアカウント登録完了をしてください。',
  pleaseCheck: '以下の{detail}\n{issues}',
  errorReceivingData: 'データ取得エラーです。{details}',
  errorUpdatingData: '入力内容に誤りがあります。{details}',
  accountActivationExpired:
    'アカウント登録完了URLの期限が切れております。再度登録手続きを行ってください。',
  saving: '保存中',
  resettingPassword: 'パスワードをリセットしています',
});

export type MainContext = ActionContext<MainState, State>;

export const actionsMain = {
  async actionPostInquiry(
    context: MainContext,
    payload: {
      payload: InquiryRequestBody;
    }
  ) {
    try {
      const response = await generalApi.inquiry(payload.payload);
      if (response.data) {
        commitAddNotification(context, {
          messageKey: m.documentSent,
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },
  async actionPostInquiryEn(
    context: MainContext,
    payload: {
      payload: InquiryRequestBody;
    }
  ) {
    try {
      const response = await generalApi.inquiryEn(payload.payload);
      if (response.data) {
        commitAddNotification(context, {
          messageKey: m.inquiryAcknowledgement,
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },

  async actionPostQuoation(
    context: MainContext,
    payload: {
      payload: QuotationRequestBody;
    }
  ) {
    try {
      const response = await generalApi.quotation(payload.payload);
      if (response.data) {
        commitAddNotification(context, {
          messageKey: m.estimateAcknowledgement,
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },
  actionSignUp,
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string }
  ) {
    try {
      const response = await generalApi.logInGetToken(
        payload.username,
        payload.password
      );
      // Check if 2FA is required.
      if (!response.data.access_token) {
        if (!response.data.tfa_phone_number) {
          // Currently only SMS is supported, so the phone number must be present.
          await dispatchRouteLogOut(context, {
            messageKey: sharedMessages.genericError,
            messageType: 'error',
          });
          return;
        }
        await dispatchRoute2FaVerify(context, {
          tfaToken: response.data.tfa_token,
          tfaPhoneNumber: response.data.tfa_phone_number,
          mode: 'sms',
        });

        return;
      }
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        await dispatchGetCurrentUser(context);
        await dispatchGetCompany(context, {
          companyId: context.state.userProfile?.company_id ?? '',
        });
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, {
          messageKey: m.loginSuccess,
          color: 'success',
        });
      } else {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context, undefined);
      }
    } catch (err) {
      const axiosError = err as AxiosError<ApiErrorResponse>;
      await dispatchRemoveLogIn(context);
      await dispatchRouteLogOut(context, {
        messageText: axiosError?.response?.data?.detail,
        messageType: 'error',
      });
    }
  },
  async actionVerify2Fa(
    context: MainContext,
    payload: { idToken: string; tfaToken: string }
  ) {
    try {
      const response = await generalApi.login2FaVerify(
        payload.idToken,
        payload.tfaToken
      );
      if (response.data) {
        saveLocalToken(response.data.access_token);
        commitSetToken(context, response.data.access_token);
        commitSetLoggedIn(context, true);
        await dispatchGetCurrentUser(context);
        await dispatchGetCompany(context, {
          companyId: context.state.userProfile?.company_id ?? '',
        });
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, {
          messageKey: m.loginSuccess,
          color: 'success',
        });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      const axiosError = err as AxiosError<ApiErrorResponse>;
      await dispatchRemoveLogIn(context);
      await dispatchRouteLogOut(context, {
        messageText: axiosError?.response?.data?.detail,
        messageType: 'error',
      });
    }
  },
  async actionGetCompany(context: MainContext, payload: { companyId: string }) {
    try {
      const response = await generalApi.getCompany(
        context.state.token,
        payload.companyId
      );
      if (response.data) {
        commitSetCompany(context, {
          uploaded_file_number: context.state.company.uploaded_file_number,
          name: response.data.name,
          uuid: response.data.uuid,
          phone: response.data.phone,
          plan_id: response.data.plan_id,
          attachment_limit_id: response.data.attachment_limit_id,
          customer_id: response.data.customer_id,
          subscription_id: response.data.subscription_id,
          next_paid_at: response.data.next_paid_at,
          subscribed_at: response.data.subscribed_at,
          renewed_at: response.data.renewed_at,
          active_features: response.data.active_features,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCompany(
    context: MainContext,
    payload: {
      params: CompanyUpdateRequestParams;
      payload: CompanyUpdateRequestBody;
    }
  ) {
    try {
      const response = await generalApi.updateCompany(
        context.state.token,
        payload.params,
        payload.payload
      );
      if (response.data) {
        commitAddNotification(context, {
          messageKey: m.companyInfoUpdated,
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionDeleteCompany(
    context: MainContext,
    payload: { companyId: string }
  ) {
    try {
      const response = await generalApi.deleteCompany(
        context.state.token,
        payload.companyId
      );
      if (response.data) {
        await dispatchLogOut(context);
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  actionGetCompanyDepartments,
  actionCreateCompanyDepartment,
  actionUpdateCompanyDepartment,
  actionDeleteCompanyDepartment,

  actionGetCompanyUsers,
  actionCreateCompanyUser,
  actionUpdateCompanyUser,
  actionResetCompanyUserPassword,
  actionDeleteCompanyUser,
  actionGetCompanyUserCsv,
  actionPostCompanyUserCsv,

  async actionGetCurrentUser(context: MainContext) {
    try {
      const response = await generalApi.getMe(context.state.token);
      if (response.data) {
        commitSetCurrentUser(context, {
          uuid: response.data.uuid,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          middle_name: response.data.middle_name,
          email: response.data.email,
          phone: response.data.phone,
          departments: response.data.departments,
          position: response.data.position,
          company_id: response.data.company_id,
          is_active: response.data.is_active,
          is_subscribe: response.data.is_subscribe,
          role_id: response.data.role_id,
          role: response.data.role,
          created_at: response.data.created_at,
          tfa_phone_number: response.data.tfa_phone_number,
          locale: response.data.locale,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionUpdateCurrentUser(
    context: MainContext,
    payload: UserUpdateRequestBody
  ) {
    try {
      const loadingNotification = {
        messageKey: m.saving,
        showProgress: true,
      };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          generalApi.updateMe(context.state.token, payload),
          new Promise((resolve) => setTimeout(resolve, 500)),
        ])
      )[0];
      commitSetCurrentUser(context, {
        uuid: response.data.uuid,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        middle_name: response.data.middle_name,
        email: response.data.email,
        phone: response.data.phone,
        departments: response.data.departments,
        position: response.data.position,
        company_id: response.data.company_id,
        is_active: response.data.is_active,
        is_subscribe: response.data.is_subscribe,
        role_id: response.data.role_id,
        role: response.data.role,
        created_at: response.data.created_at,
        tfa_phone_number: response.data.tfa_phone_number,
        locale: response.data.locale,
      });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        messageKey: m.userInfoUpdated,
        color: 'success',
      });
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCurrentUser2Fa(
    context: MainContext,
    payload: {
      noNotification?: boolean;
      patch: {
        tfa_phone_number: string;
      };
    }
  ) {
    try {
      const response = await generalApi.updateMe2Fa(
        context.state.token,
        payload.patch
      );
      if (response.data && !payload.noNotification) {
        commitAddNotification(context, {
          messageKey: m.tfaUpdated,
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (!payload.noNotification) {
        await dispatchCheckApiError(context, axiosError);
      }
      throw axiosError;
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await generalApi.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetCurrentUser(context, {
            uuid: response.data.uuid,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            middle_name: response.data.middle_name,
            email: response.data.email,
            phone: response.data.phone,
            departments: response.data.departments,
            position: response.data.position,
            company_id: response.data.company_id,
            is_active: response.data.is_active,
            is_subscribe: response.data.is_subscribe,
            role_id: response.data.role_id,
            role: response.data.role,
            created_at: response.data.created_at,
            tfa_phone_number: response.data.tfa_phone_number,
            locale: response.data.locale,
          });
          await dispatchGetCompany(context, {
            companyId: context.state.userProfile?.company_id ?? '',
          });
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, '');
    commitSetLoggedIn(context, false);
    commitSetCurrentUser(context, { ...NewUser });
    commitSetCompany(context, { ...initialCompanyState });
    commitSetDepartments(context, []);
  },
  async actionLogOut(
    context: MainContext,
    params: {
      messageKey: string;
      messageType: string;
    } = {
      messageKey: m.logoutSuccess,
      messageType: 'success',
    }
  ) {
    try {
      if (!context.state.token) {
        return;
      }
      await generalApi.logout(context.state.token);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorStatus = e.response?.status || e.status;
        if (errorStatus && errorStatus >= 500) {
          commitAddNotification(context, {
            messageKey: m.logoutFailed,
            color: 'error',
          });
        }
      }
    }
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context, params);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
  },
  actionRouteLogOut(
    _: MainContext,
    payload:
      | { messageKey?: string; messageText?: string; messageType: string }
      | undefined
  ) {
    router.push({
      name: 'login',
      params: payload,
      // Add query to force reload of the page when a message is present.
      query:
        payload?.messageKey || payload?.messageText
          ? { r: Date.now().toString() }
          : undefined,
    });
  },
  async actionCheckApiError(
    context: MainContext,
    payload: AxiosError<ApiErrorResponse>
  ) {
    const errorStatus = payload.response?.status || payload.status;
    const errorDetail = payload.response?.data as { detail?: string };

    switch (errorStatus) {
      case 401: {
        commitAddNotification(context, {
          messageKey: m.tokenExpired,
          color: 'error',
        });
        await dispatchLogOut(context);
        break;
      }
      case 403: {
        commitAddNotification(context, {
          messageKey: m.noPermission,
          messageValues: { details: errorDetail?.detail ?? '' },
          color: 'error',
        });
        break;
      }
      case 400: {
        let errorMessage = '';

        if (payload.config?.method === 'get') {
          errorMessage = m.errorReceivingData;
        } else {
          errorMessage = m.errorUpdatingData;
        }
        commitAddNotification(context, {
          messageKey: errorMessage,
          messageValues: { details: errorDetail?.detail ?? '' },
          color: 'error',
        });
        break;
      }
      case 404: {
        commitAddNotification(context, {
          messageKey: m.resourceNotFound,
          messageValues: { details: errorDetail?.detail ?? '' },
          color: 'error',
        });
        break;
      }
      case 408: {
        commitAddNotification(context, {
          messageKey: m.requestTimeout,
          messageValues: { details: errorDetail?.detail ?? '' },
          color: 'error',
        });
        break;
      }
      case 413: {
        commitAddNotification(context, {
          messageKey: m.payloadTooLarge,
          messageValues: { details: errorDetail?.detail ?? '' },
          color: 'error',
        });
        break;
      }
      case 415: {
        commitAddNotification(context, {
          messageKey: m.invalidFileFormat,
          messageValues: { details: errorDetail?.detail ?? '' },
          color: 'error',
        });
        break;
      }
      case 500:
      case 501:
      case 502:
      case 503:
      case 504: {
        commitAddNotification(context, {
          messageKey: m.serverError,
          messageValues: {
            status: `${errorStatus}`,
            details: errorDetail?.detail ?? '',
          },
          color: 'error',
        });
        break;
      }
      default: {
        commitAddNotification(context, {
          messageKey: m.unknownError,
          messageValues: { details: errorDetail?.detail ?? '' },
          color: 'error',
        });
        break;
      }
    }
  },
  actionRouteLoggedIn() {
    if (
      router.currentRoute.path?.startsWith('/login') ||
      router.currentRoute.path === '/signup'
    ) {
      const redirect = router.currentRoute.query.redirect;
      if (typeof redirect === 'string' && redirect.startsWith('/')) {
        router.push({ path: redirect });
      } else {
        router.push({ name: 'sdsUpload' });
      }
    }
  },
  actionRoute2FaVerify(
    _: MainContext,
    payload: { tfaToken: string; tfaPhoneNumber: string; mode: string }
  ) {
    router.push({ name: 'loginVerify', params: payload });
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      messageKey: m.checkingEmail,
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification);
      await generalApi.passwordRecovery(payload.username);
      commitRemoveNotification(context, loadingNotification);

      commitAddNotification(context, {
        messageKey: m.passwordRecoveryEmailSent,
        color: 'success',
      });

      await dispatchLogOut(context);
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      commitAddNotification(context, {
        color: 'error',
        messageKey: m.invalidEmail,
      });
      throw axiosError;
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string }
  ) {
    const loadingNotification = {
      messageKey: m.resettingPassword,
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        generalApi.resetPassword(payload.password, payload.token),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        messageKey: m.passwordResetComplete,
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        messageKey: m.passwordResetFailed,
      });
    }
  },
  async actionActivateUser(context: MainContext, payload: { token: string }) {
    const notificationActivating = {
      messageKey: m.notificationActivating,
      showProgress: true,
    };
    const notificationActivated = {
      messageKey: m.accountRegistrationComplete,
      showProgress: false,
    };
    try {
      commitAddNotification(context, notificationActivating);
      await generalApi.activateUser(payload);
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, notificationActivated);
    } catch (error) {
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, {
        color: 'error',
        messageKey: m.accountActivationExpired,
      });
    }
  },
  async actionRegenerateActivationMail(
    context: MainContext,
    payload: { email: string }
  ) {
    const notificationActivating = {
      messageKey: m.accountActivationMailSent,
      showProgress: true,
    };
    const notificationActivated = {
      messageKey: m.accountActivationReceived,
      showProgress: false,
    };
    try {
      commitAddNotification(context, notificationActivating);
      await generalApi.regenerateActivationMail({ email: payload.email });
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, notificationActivated);
    } catch (error) {
      commitRemoveNotification(context, notificationActivating);
      commitAddNotification(context, {
        color: 'error',
        messageKey: m.processingError,
      });
    }
  },
  async actionGetCompanyWhitelistIp(
    context: MainContext,
    payload: {
      companyId: string;
    }
  ) {
    try {
      return await generalApi.getCompanyWhitelistIp(
        context.state.token,
        payload.companyId
      );
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetClientIp(context: MainContext) {
    try {
      return await generalApi.getClientIp(context.state.token);
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      throw axiosError;
    }
  },

  async actionCreateCompanyWhitelistIp(
    context: MainContext,
    payload: {
      payload: CompanyWhitelistIpCreateRequestBody;
      companyId: string;
    }
  ) {
    try {
      const response = await generalApi.createCompanyWhitelistIp(
        context.state.token,
        payload.companyId,
        payload.payload
      );
      if (response) {
        commitAddNotification(context, {
          messageKey: m.ipAddressAdded,
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDeleteCompanyWhitelistIp(
    context: MainContext,
    payload: {
      companyId: string;
      ipAddress: string;
    }
  ) {
    try {
      const response = await generalApi.deleteCompanyWhitelistIp(
        context.state.token,
        payload.companyId,
        payload.ipAddress
      );
      if (response) {
        commitAddNotification(context, {
          messageKey: m.ipAddressDeleted,
          color: 'success',
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanySds(
    context: MainContext,
    payload: {
      companyId: string;
      sdsId: string;
    }
  ) {
    try {
      const response = await generalApi.getCompanySds(
        context.state.token,
        payload.companyId,
        payload.sdsId
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionGetCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      data: GetCompanySdsesRequestQuery;
    }
  ) {
    try {
      const response = await generalApi.getCompanySdses(
        context.state.token,
        payload.companyId,
        payload.data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  // エクスポートモーダルで実行可能な操作情報を取得する
  async actionGetCompanyExportOperations(
    context: MainContext,
    payload: {
      companyId: string;
    }
  ) {
    try {
      const response = await generalApi.getCompanyExportOperations(
        context.state.token,
        payload.companyId
      );

      return response;
    } catch (error) {
      // TODO: Add proper error handling
      const axiosError = error as AxiosError<ApiErrorResponse>;
      throw axiosError;
    }
  },

  async actionExportCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      data: GetCompanySdsesAsExcelRequestQuery;
    }
  ) {
    try {
      const response = await generalApi.exportCompanySdses(
        context.state.token,
        payload.companyId,
        payload.data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (axiosError.response?.status !== 400) {
        await dispatchCheckApiError(context, axiosError);
      } else {
        const { detail, denied_sds_list: deniedSdsList } = axiosError.response
          .data as ApiErrorResponse;
        commitAddNotification(context, {
          messageKey: m.pleaseCheck,
          messageValues: {
            detail: detail ?? '',
            issues: (deniedSdsList ?? []).map((s) => s.name).join(', '),
          },
          color: 'error',
        });
      }
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionGetCompanySdsesCount(
    context: MainContext,
    payload: {
      companyId: string;
      data: GetCompanySdsesCountRequestQuery;
    }
  ) {
    try {
      const response = await generalApi.getCompanySdsesCount(
        context.state.token,
        payload.companyId,
        payload.data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanySdsPdf(
    context: MainContext,
    payload: {
      companyId: string;
      fileUuid: string;
    }
  ) {
    try {
      const response = await generalApi.getCompanySdsPdf(
        context.state.token,
        payload.companyId,
        payload.fileUuid
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetCompanySdsAttachmentPdf(
    context: MainContext,
    payload: {
      companyId: string;
      sdsUuid: string;
      attachmentUuid: string;
    }
  ) {
    try {
      const response = await generalApi.getCompanySdsAttachmentPdf(
        context.state.token,
        payload.companyId,
        payload.sdsUuid,
        payload.attachmentUuid
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionGetGcsSignedUrl(
    context: MainContext,
    payload: {
      companyId: string;
      sdsList: Array<CreateCompanySdsRequestUnit>;
    }
  ) {
    try {
      const response = await generalApi.getGcsSignedUrl(
        context.state.token,
        payload.companyId,
        payload.sdsList
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionGetAttachmentGcsSignedUrl(
    context: MainContext,
    payload: {
      companyId: string;
      sdsId: string;
      attachments: Array<CreateAttachmentRequestUnit>;
    }
  ) {
    try {
      const response = await generalApi.getAttachmentGcsSignedUrl(
        context.state.token,
        payload.companyId,
        payload.sdsId,
        payload.attachments
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },
  async actionCreateCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      departmentIdList: string[];
      sdsList: Array<CreateCompanySdsRequestUnit>;
    }
  ) {
    try {
      const { companyId, departmentIdList, sdsList } = payload;
      const response = await generalApi.createSdses(
        context.state.token,
        companyId,
        {
          department_id_list: departmentIdList,
          sds_list: sdsList,
        }
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        messageKey: m.uploadFailed,
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      data: UpdateCompanySdsesRequestBody;
    }
  ) {
    try {
      const { companyId, data } = payload;
      const response = await generalApi.updateCompanySdses(
        context.state.token,
        companyId,
        data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDeleteCompanySdses(
    context: MainContext,
    payload: {
      companyId: string;
      data: DeleteCompanySdsesRequestBody;
    }
  ) {
    try {
      const { companyId, data } = payload;
      const response = await generalApi.deleteCompanySdses(
        context.state.token,
        companyId,
        data
      );
      commitAddNotification(context, {
        messageKey: m.fileDeleted,
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (axiosError.response?.status !== 400) {
        await dispatchCheckApiError(context, axiosError);
      } else {
        const { detail, denied_sds_list: deniedSdsList } = axiosError.response
          .data as ApiErrorResponse;
        commitAddNotification(context, {
          messageKey: m.pleaseCheck,
          messageValues: {
            detail: detail ?? '',
            issues: (deniedSdsList ?? []) // Fallback to an empty array
              .map((s) => s.name)
              .join(', '),
          },
          color: 'error',
        });
      }
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDownloadCompanySdsesCSV(
    context: MainContext,
    payload: {
      companyId: string;
      data: DownloadCompanySdsesCSVRequestQuery;
    }
  ) {
    try {
      const { companyId, data } = payload;
      const response = await generalApi.downloadCompanySdsesCSV(
        context.state.token,
        companyId,
        data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async dispatchUploadCompanySdsesCSV(
    context: MainContext,
    payload: {
      companyId: string;
      data: UploadCompanySdsesCSVRequestBody;
    }
  ) {
    try {
      const { companyId, data } = payload;
      const response = await generalApi.uploadCompanySdsesCSV(
        context.state.token,
        companyId,
        data
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionUpdateCompanySds(
    context: MainContext,
    payload: {
      companyId: string;
      fileId: string;
      form: UpdateCompanySdsRequestBody;
    }
  ) {
    try {
      const response = await generalApi.updateCompanySds(
        context.state.token,
        payload.companyId,
        payload.fileId,
        payload.form
      );
      commitAddNotification(context, {
        messageKey: m.updateSuccess,
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        messageKey: m.processFailed,
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  actionGetDynamicList,
  actionGetDynamicLists,
  actionGetDynamicListResults,
  actionGetExportDynamicListResults,
  actionCreateDynamicList,
  actionRequestDynamicList,
  actionResumeDynamicLists,
  actionSuspendDynamicLists,

  async actionUpdateReconfirmationSds(
    context: MainContext,
    payload: {
      companyId: string;
      sdsId: string;
      form: UpdateReconfirmationSdsRequestBody;
    }
  ) {
    try {
      const response = await generalApi.updateReconfirmationSds(
        context.state.token,
        payload.companyId,
        payload.sdsId,
        payload.form
      );
      commitAddNotification(context, {
        messageKey: m.updateSuccess,
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        messageKey: m.processFailed,
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetExportCreateSimple(
    context: MainContext,
    payload: {
      sdsId: string;
    }
  ) {
    try {
      const response = await generalApi.getExportCreateSimple(
        context.state.token,
        payload.sdsId
      );
      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionGetExportCreateSimpleV3(
    context: MainContext,
    payload: {
      sdsId: string;
    }
  ) {
    try {
      const response = await generalApi.getExportCreateSimpleV3(
        context.state.token,
        payload.sdsId
      );
      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionCancelExportCreateSimple(context: MainContext) {
    try {
      const response = generalApi.cancelCreateSimpleExportRequest();
      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
    }
  },

  async actionCancelExportCreateSimpleV3(context: MainContext) {
    try {
      const response = generalApi.cancelCreateSimpleV3ExportRequest();
      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
    }
  },

  async actionCreateReanalysisSds(
    context: MainContext,
    payload: {
      sdsId: string;
      comment: string;
    }
  ) {
    try {
      const response = await generalApi.createReanalysisSds(
        context.state.token,
        payload
      );

      commitAddNotification(context, {
        messageKey: m.redataRequested,
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
    }
  },

  async actionPutDynamicListResult(
    context: MainContext,
    payload: {
      sdsId: string;
      dynamicListId: number;
      dynamicListResult: DynamicListResultRequestBody;
    }
  ) {
    try {
      const response = await generalApi.putDynamicListResult(
        context.state.token,
        payload.sdsId,
        payload.dynamicListId,
        payload.dynamicListResult
      );
      commitAddNotification(context, {
        messageKey: m.checklistUpdated,
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        messageKey: m.processFailed,
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionPostDynamicListResult(
    context: MainContext,
    payload: {
      sdsId: string;
      dynamicListId: number;
    }
  ) {
    try {
      const response = await generalApi.postDynamicListResult(
        context.state.token,
        payload.sdsId,
        payload.dynamicListId
      );
      commitAddNotification(context, {
        messageKey: m.checklistAdded,
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        messageKey: m.processFailed,
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionDeleteDynamicListResult(
    context: MainContext,
    payload: {
      sdsId: string;
      dynamicListId: number;
    }
  ) {
    try {
      const response = await generalApi.deleteDynamicListResult(
        context.state.token,
        payload.sdsId,
        payload.dynamicListId
      );
      commitAddNotification(context, {
        messageKey: m.checklistRemoved,
        color: 'success',
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      await dispatchCheckApiError(context, axiosError);
      commitAddNotification(context, {
        messageKey: m.processFailed,
        color: 'error',
      });
      if (axiosError?.response?.status === 403) {
        await dispatchLogOut(context);
      }
      throw axiosError;
    }
  },

  async actionSetUserLocale(context: MainContext, locale: Locale) {
    // Get token for authentication
    const token = getLocalToken();
    if (token) {
      try {
        // Update language in database
        await generalApi.updateUserLanguage(token, {
          locale: locale,
        });
      } catch (error) {
        const axiosError = error as AxiosError<ApiErrorResponse>;
        await dispatchCheckApiError(context, axiosError);
        commitAddNotification(context, {
          messageKey: m.processFailed,
          color: 'error',
        });
        if (axiosError?.response?.status === 403) {
          await dispatchLogOut(context);
        }
        throw axiosError;
      }
    }
    commitSetUserLocale(context, locale);
  },

  actionGetCompanyTags,
  actionCreateCompanyTag,
  actionUpdateCompanyTag,
  actionDeleteCompanyTag,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchPostInquiry = dispatch(actionsMain.actionPostInquiry);
export const dispatchPostInquiryEn = dispatch(actionsMain.actionPostInquiryEn);
export const dispatchPostQuotation = dispatch(actionsMain.actionPostQuoation);
export const dispatchCheckApiError = dispatch(actionsMain.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actionsMain.actionCheckLoggedIn);
export const dispatchGetCompany = dispatch(actionsMain.actionGetCompany);
export const dispatchGetCompanyDepartments = dispatch(
  actionsMain.actionGetCompanyDepartments
);
export const dispatchCreateCompanyDepartment = dispatch(
  actionsMain.actionCreateCompanyDepartment
);
export const dispatchUpdateCompanyDepartment = dispatch(
  actionsMain.actionUpdateCompanyDepartment
);
export const dispatchDeleteCompanyDepartment = dispatch(
  actionsMain.actionDeleteCompanyDepartment
);
export const dispatchUpdateCompany = dispatch(actionsMain.actionUpdateCompany);
export const dispatchDeleteCompany = dispatch(actionsMain.actionDeleteCompany);
export const dispatchGetCurrentUser = dispatch(
  actionsMain.actionGetCurrentUser
);
export const dispatchSignUp = dispatch(actionsMain.actionSignUp);
export const dispatchLogIn = dispatch(actionsMain.actionLogIn);
export const dispatchLogOut = dispatch(actionsMain.actionLogOut);
export const dispatchRegenerateActivationMail = dispatch(
  actionsMain.actionRegenerateActivationMail
);
export const dispatchResetCompanyUserPassword = dispatch(
  actionsMain.actionResetCompanyUserPassword
);
export const dispatchUserLogOut = dispatch(actionsMain.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actionsMain.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actionsMain.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actionsMain.actionRouteLogOut);
export const dispatchVerify2Fa = dispatch(actionsMain.actionVerify2Fa);
export const dispatchRoute2FaVerify = dispatch(
  actionsMain.actionRoute2FaVerify
);
export const dispatchUpdateCurrentUser = dispatch(
  actionsMain.actionUpdateCurrentUser
);
export const dispatchUpdateCompanyUser = dispatch(
  actionsMain.actionUpdateCompanyUser
);
export const dispatchUpdateCurrentUser2Fa = dispatch(
  actionsMain.actionUpdateCurrentUser2Fa
);
export const dispatchRemoveNotification = dispatch(
  actionsMain.removeNotification
);
export const dispatchPasswordRecovery = dispatch(actionsMain.passwordRecovery);
export const dispatchResetPassword = dispatch(actionsMain.resetPassword);
export const dispatchActivateUser = dispatch(actionsMain.actionActivateUser);
export const dispatchGetCompanyUsers = dispatch(
  actionsMain.actionGetCompanyUsers
);
export const dispatchCreateCompanyUser = dispatch(
  actionsMain.actionCreateCompanyUser
);
export const dispatchDeleteCompanyUser = dispatch(
  actionsMain.actionDeleteCompanyUser
);
export const dispatchGetCompanyUserCsv = dispatch(
  actionsMain.actionGetCompanyUserCsv
);
export const dispatchPostCompanyUserCsv = dispatch(
  actionsMain.actionPostCompanyUserCsv
);
export const dispatchGetCompanySds = dispatch(actionsMain.actionGetCompanySds);
export const dispatchGetCompanySdses = dispatch(
  actionsMain.actionGetCompanySdses
);
export const dispatchGetCompanyExportOperations = dispatch(
  actionsMain.actionGetCompanyExportOperations
);
export const dispatchExportSdses = dispatch(
  actionsMain.actionExportCompanySdses
);
export const dispatchGetCompanySdsesCount = dispatch(
  actionsMain.actionGetCompanySdsesCount
);
export const dispatchGetCompanySdsPdf = dispatch(
  actionsMain.actionGetCompanySdsPdf
);
export const dispatchGetCompanySdsAttachmentPdf = dispatch(
  actionsMain.actionGetCompanySdsAttachmentPdf
);
export const dispatchCreateCompanySdses = dispatch(
  actionsMain.actionCreateCompanySdses
);
export const dispatchDeleteCompanySdses = dispatch(
  actionsMain.actionDeleteCompanySdses
);

export const dispatchUpdateCompanySds = dispatch(
  actionsMain.actionUpdateCompanySds
);
export const dispatchUpdateCompanySdses = dispatch(
  actionsMain.actionUpdateCompanySdses
);
export const dispatchUploadCompanySdsesCSV = dispatch(
  actionsMain.dispatchUploadCompanySdsesCSV
);
export const dispatchDownloadCompanySdsesCSV = dispatch(
  actionsMain.actionDownloadCompanySdsesCSV
);
export const dispatchUpdateReconfirmationSds = dispatch(
  actionsMain.actionUpdateReconfirmationSds
);
export const dispatchGetCompanyWhitelistIp = dispatch(
  actionsMain.actionGetCompanyWhitelistIp
);
export const dispatchCreateCompanyWhitelistIp = dispatch(
  actionsMain.actionCreateCompanyWhitelistIp
);
export const dispatchDeleteCompanyWhitelistIp = dispatch(
  actionsMain.actionDeleteCompanyWhitelistIp
);
export const dispatchGetGcsSignedUrl = dispatch(
  actionsMain.actionGetGcsSignedUrl
);
export const dispatchGetAttachmentGcsSignedUrl = dispatch(
  actionsMain.actionGetAttachmentGcsSignedUrl
);
export const dispatchGetClientIp = dispatch(actionsMain.actionGetClientIp);
export const dispatchGetDynamicList = dispatch(
  actionsMain.actionGetDynamicList
);
export const dispatchGetDynamicLists = dispatch(
  actionsMain.actionGetDynamicLists
);
export const dispatchResumeDynamicLists = dispatch(
  actionsMain.actionResumeDynamicLists
);
export const dispatchSuspendDynamicLists = dispatch(
  actionsMain.actionSuspendDynamicLists
);
export const dispatchGetDynamicListResults = dispatch(
  actionsMain.actionGetDynamicListResults
);
export const dispatchGetExportDynamicListResults = dispatch(
  actionsMain.actionGetExportDynamicListResults
);
export const dispatchCreateDynamicList = dispatch(
  actionsMain.actionCreateDynamicList
);
export const dispatchRequestDynamicList = dispatch(
  actionsMain.actionRequestDynamicList
);
export const dispatchGetExportCreateSimple = dispatch(
  actionsMain.actionGetExportCreateSimple
);
export const dispatchGetExportCreateSimpleV3 = dispatch(
  actionsMain.actionGetExportCreateSimpleV3
);
export const dispatchCancelCreateSimpleExportRequest = dispatch(
  actionsMain.actionCancelExportCreateSimple
);
export const dispatchCancelCreateSimpleV3ExportRequest = dispatch(
  actionsMain.actionCancelExportCreateSimpleV3
);
export const dispatchCreateReanalysisSds = dispatch(
  actionsMain.actionCreateReanalysisSds
);
export const dispatchPutDynamicListResult = dispatch(
  actionsMain.actionPutDynamicListResult
);
export const dispatchPostDynamicListResult = dispatch(
  actionsMain.actionPostDynamicListResult
);
export const dispatchDeleteDynamicListResult = dispatch(
  actionsMain.actionDeleteDynamicListResult
);
export const dispatchSetUserLocale = dispatch(actionsMain.actionSetUserLocale);

export const dispatchCreateCompanyTag = dispatch(
  actionsMain.actionCreateCompanyTag
);

export const dispatchGetCompanyTags = dispatch(
  actionsMain.actionGetCompanyTags
);

export const dispatchDeleteCompanyTag = dispatch(
  actionsMain.actionDeleteCompanyTag
);

export const dispatchUpdateCompanyTag = dispatch(
  actionsMain.actionUpdateCompanyTag
);
